<template>
<Layout>
        <PageHeader :title="title" :items="items" />
    <div class=" container-fluid p-4">

        <!--                建立表单-->
        <b-card bg-variant="light" style="background-color:white!important;">
            <div style="position:relative;bottom: 35px;background-color:transparent;font-weight: bolder;font-size: 20px">{{$t('activity')}}</div>
            <b-form>
                <b-form-group
                        label-cols-lg="12"
                        label=""
                        label-size="lg"
                        label-class="font-weight-bold pt-0"
                        class="mb-0">
                    <ap-autocomplete @selectedItem="processDestination" url="/hotel-destination" :locale="$i18n.locale" list-name="tour-destination" :subTitle="'cityName'" :title="'label'" :placeholder="$t('whereAreYouGoing')"></ap-autocomplete>
                    <b-row style="margin-top: 30px">
                        <b-col md="3">
                            <b-form-datepicker id="check_in_date" :placeholder="$t('startDate')" :locale="$i18n.locale"
                                               v-model="startDate" :min="startMinDate" class="mb-2" required></b-form-datepicker>
                        </b-col>
                        <b-col md="3">
                            <b-form-datepicker id="check_out_date" :placeholder="$t('endDate')" :locale="$i18n.locale"
                                               v-model="endDate" :min="new Date(new Date(this.startDate).setDate(new Date(this.startDate).getDate()+1))" class="mb-2" required></b-form-datepicker>
                        </b-col>
                        <b-col cols="4">
                            <b-button variant="outline-primary" v-b-modal.modal-passenger style="min-width: 300px">{{adultCount}} {{$t('adult')}} {{$t('and')}} {{childCount}} {{$t('child')}}</b-button>
                            <b-modal
                                    id="modal-passenger"
                                    modal-class="modal-primary"
                                    ok-only
                                    centered
                                    :title="$t('tickets')"
                                    :ok-title="$t('ok')"
                                    header-class="text-primary"
                            >
                                <b-card-text style="height: 300px">
                                    <b-row>
                                        <b-col cols="3">
                                            <span style="font-weight: bold;font-size: 20px">{{$t('adult')}}</span>
                                        </b-col>
                                        <b-col cols="5" offset="4">
                                            <b-row>
                                                <b-button size="sm" class="mr-3" style="" @click="adultCount++" :disabled="adultCount>3">+</b-button>
                                                <b-form-input :value="adultCount" disabled style="width: 50%"></b-form-input>
                                                <b-button size="sm" class="ml-3" @click="adultCount--" :disabled="adultCount<2">-</b-button>
                                            </b-row>

                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-5">
                                        <b-col cols="3">
                                            <span style="font-weight: bold;font-size: 20px">{{$t('child')}}</span>
                                        </b-col>
                                        <b-col cols="5" offset="4">
                                            <b-row>
                                                <b-button size="sm" class="mr-3" style="" @click="childCount++" :disabled="childCount>3">+</b-button>
                                                <b-form-input :value="childCount" disabled style="width: 50%"></b-form-input>
                                                <b-button size="sm" class="ml-3" @click="childCount--" :disabled="childCount<1">-</b-button>
                                            </b-row>

                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-3">
                                        <b-col cols="6" v-for="(item,index) in childList" :key="index" class="mt-3">
                                            <span>{{$t('child')}} {{index+1}} {{$t('age')}}</span>
                                            <b-form-select v-model="item.age" :options="childAgeOptions"></b-form-select>
                                        </b-col>
                                    </b-row>
                                </b-card-text>
                            </b-modal>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col offset-md="5">
                            <b-button variant="secondary" offset-md="4" @click="activityCitySearch()">{{$t('searchActivity')}}</b-button>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
        </b-card>
    </div>
    <div class="c_wrap container-fluid p-4" style="margin-top: 10%" >
        <div class="c_carousel"></div>
        <carousel-3d
                :autoplay="true"
                :autoplayTimeout="3000"
                :display="5"
                :width="480"
                :height="330"
                :animationSpeed="1000"
        >

            <slide class="carousel_box" v-for="(item, index) in earthData" :key="index" :index="index">
                <div class="carousel_flashlight">
                    <img :src="item.url" alt="" style="min-width: 500px;min-height: 500px">
                </div>
            </slide>
        </carousel-3d>
    </div>

</Layout>
</template>

<script>

    import Layout from "../layouts/main";
    import PageHeader from "@/components/page-header";
    
    import { Carousel3d, Slide } from 'vue-carousel-3d'
    import {mapState} from "vuex";
    import activityApi from "../../api/activityApi";
    import ApAutocomplete from "../../components/forms-element/autocomplete/ApAutocomplete";

    export default {
        name: "ActivityHome",
        components:{
            Layout,
            PageHeader,
            Carousel3d,
            Slide,
            ApAutocomplete
        },
        data(){
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            const minDate = new Date(today)
            minDate.setDate(minDate.getDate() - 1);
            const maxDate = new Date(today)
            maxDate.setMonth(maxDate.getMonth() + 2)
            maxDate.setDate(15);
            return{
                title: "Activity",
                items: [
                    {
                        // #TODO
                        text: "Jebsen Travel",
                    },
                    {
                        text: "Activity",
                        active: true,
                    },
                ],
                autoCompleteData:[{
                    cityId: "Hello",
                    cityName: "Hello City"
                }],
                startDate:'',
                endDate:'',
                hotel_destination:'',
                adultCount:1,
                adultOptions:[
                    {
                        value:1,text:'1 Adult'
                    },
                    {
                        value:2,text:'2 Adults'
                    },
                    {
                        value:3,text:'3 Adults'
                    },
                    {
                        value:4,text:'4 Adults'
                    }
                ],
                childCount:0,
                childOptions:[
                    {
                        value:0,text:'No Child'
                    },
                    {
                        value:1,text:'1 Child'
                    },
                    {
                        value:2,text:'2 Children'
                    },
                    {
                        value:3,text:'3 Children'
                    },
                    {
                        value:4,text:'4 Children'
                    }
                ],
                earthData: [
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.activitiesbank.com/48078/ENG/XL/1.jpeg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.activitiesbank.com/48078/ENG/XL/2.jpeg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.activitiesbank.com/48103/ENG/XL/48103-2.jpeg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"https://media.activitiesbank.com/47621/ENG/XL/47621_1.jpeg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.activitiesbank.com/51728/ENG/XL/Cafe100_Dining%20Package_Asian%20rice%20bowl.jpg"
                    },
                    {
                        stext: "标题1",
                        sdescription: "1",
                        url:"https://media.activitiesbank.com/51728/ENG/XL/Restaurant%20interior.jpg"
                    },
                    {
                        stext: "标题2",
                        sdescription: "2",
                        url:"https://media.activitiesbank.com/51728/ENG/XL/sky100_cafe100_Beer.jpg"
                    },
                    {
                        stext: "标题3",
                        sdescription: "3",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/10/103186/103186a_hb_a_001.jpg"
                    },
                    {
                        stext: "标题4",
                        sdescription: "4",
                        url:"http://media.roomstays.travel/photos.hotelbeds.com/giata/68/682128/682128a_hb_l_001.jpg"
                    },
                    {
                        stext: "标题5",
                        sdescription: "5",
                        url:"https://media.roomstays.travel/photos.hotelbeds.com/giata/61/614491/614491a_hb_l_001.jpg"
                    },
                ],
                destinationKey:'',
                cityId:'',
                startMinDate:minDate,
                childList:[],
                childAgeOptions:[
                    {
                        value:0,text:"0-1 Year Old"
                    },
                    {
                        value:1,text:"1 Year Old"
                    },
                    {
                        value:2,text:"2 Years Old"
                    },
                    {
                        value:3,text:"3 Years Old"
                    },
                    {
                        value:4,text:"4 Years Old"
                    },
                    {
                        value:5,text:"5 Years Old"
                    },
                    {
                        value:6,text:"6 Years Old"
                    },
                    {
                        value:7,text:"7 Years Old"
                    },
                    {
                        value:8,text:"8 Years Old"
                    },
                    {
                        value:9,text:"9 Years Old"
                    },
                    {
                        value:10,text:"10 Years Old"
                    },
                    {
                        value:11,text:"11 Years Old"
                    },
                    {
                        value:12,text:"12 Years Old"
                    },

                ],
            }
        },
        methods:{
            activityCitySearch(){
                let startDate = this.startDate;
                let endDate = this.endDate;
                let adultCount = this.adultCount;
                let childCount = this.childCount;
                let cityId = this.cityId
                let childList = JSON.stringify(this.childList);
                this.$router.push({
                    path:'/activity/list',
                    query:{
                        startDate: startDate,
                        endDate:endDate,
                        adultCount:adultCount,
                        childCount:childCount,
                        cityId:cityId,
                        childList:childList
                    }
                });
            },
            //根据电脑分辨率设置轮播图宽度
            // changeWidth() {
            //     if (
            //         document.body.clientWidth >= 1700 &&
            //         document.body.clientWidth < 1920
            //     ) {
            //         this.cWidth = 260;
            //     }
            //     if (
            //         document.body.clientWidth >= 1500 &&
            //         document.body.clientWidth < 1700
            //     ) {
            //         this.cWidth = 220;
            //     }
            //     if (document.body.clientWidth < 1500) {
            //         this.cWidth = 180;
            //     }
            // },
            selectDestintaion: function (key) {
                console.log(key);
                for (let i = 0; i < this.autoCompleteData.length; i++) {
                    if (key ==this.autoCompleteData[i].label){
                        this.cityId = this.autoCompleteData[i].cityId
                    }
                }
            },
            processDestination(item){
                console.log(JSON.stringify(item))
                this.cityId = item.cityId
            },
        },
        computed:{
            ...mapState(["LayoutType", "Theme", "setHorizontalLayout"]),
        },
        created(){
        },
        mounted() {
        },
        watch:{
            destinationKey: function() {
                this.$nextTick(function(){
                    activityApi.getActivityDestinationAutocomplete(this.destinationKey).then(res=>{
                        this.autoCompleteData = [];
                        this.autoCompleteData = res.data;
                        console.log(this.autoCompleteData);
                        console.log(this.destinationKey)
                    })
                })
            },
            childCount:function () {
                this.$nextTick(function(){
                    this.childList=[]
                    for(let i=0;i<this.childCount;i++){
                        let childInfo={
                            age:0,
                            quantity:1
                        }
                        this.childList.push(childInfo);
                    }
                })
            },
        }


    }
</script>

<style scoped>

</style>